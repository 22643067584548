// ExpenseConstants.js
const negativeCategories = [
  {
    label: 'Operating Expenses',
    value: 'OPERATING_EXPENSES',
    abbrv: 'Oper. Exp.',
  },
  {
    value: 'ACCOUNTING_FEES',
    label: 'Accounting Fees',
    abbrv: 'Acct. Fees',
  },
  {
    value: 'ADVERTISING_PROMOTION',
    label: 'Advertising & Promotion',
    abbrv: 'Adv. & Promo.',
  },
  {
    value: 'BANK_SERVICE_CHARGES',
    label: 'Bank Service Charges',
    abbrv: 'Bank Svc. Charges',
  },
  {
    value: 'BUSINESS_LICENSES_PERMITS',
    label: 'Business Licenses & Permits',
    abbrv: 'Licenses & Permits',
  },
  {
    value: 'COMPUTER_HARDWARE',
    label: 'Computer - Hardware',
    abbrv: 'Comp. Hardware',
  },
  {
    value: 'COMPUTER_HOSTING',
    label: 'Computer - Hosting',
    abbrv: 'Comp. Hosting',
  },
  {
    value: 'COMPUTER_INTERNET',
    label: 'Computer - Internet',
    abbrv: 'Comp. Internet',
  },
  {
    value: 'COMPUTER_SOFTWARE',
    label: 'Computer - Software',
    abbrv: 'Comp. Software',
  },
  {
    value: 'DUES_SUBSCRIPTIONS',
    label: 'Dues & Subscriptions',
    abbrv: 'Dues & Subs.',
  },
  { value: 'EVENT_FEES', label: 'Event Fees', abbrv: 'Event Fees' },
  {
    value: 'INSURANCE_VEHICLES',
    label: 'Insurance - Vehicles',
    abbrv: 'Ins. Vehicles',
  },
  {
    value: 'MEALS_ENTERTAINMENT',
    label: 'Meals and Entertainment',
    abbrv: 'Meals & Ent.',
  },
  {
    value: 'OFFICE_SUPPLIES',
    label: 'Office Supplies',
    abbrv: 'Office Supplies',
  },
  {
    value: 'PROFESSIONAL_FEES',
    label: 'Professional Fees',
    abbrv: 'Prof. Fees',
  },
  {
    value: 'PAYROLL_SALARY_WAGES',
    label: 'Payroll - Salary & Wages',
    abbrv: 'Payroll',
  },
  { value: 'RENT_EXPENSE', label: 'Rent Expense', abbrv: 'Rent Expense' },
  {
    value: 'REPAIRS_MAINTENANCE',
    label: 'Repairs & Maintenance',
    abbrv: 'Repairs & Maint.',
  },
  {
    value: 'TELEPHONE_LAND_LINE',
    label: 'Telephone - Land Line',
    abbrv: 'Telephone',
  },
  {
    value: 'TRAVEL_EXPENSE',
    label: 'Travel Expense',
    abbrv: 'Travel',
  },
  { value: 'UTILITIES', label: 'Utilities', abbrv: 'Utilities' },
  { value: 'VEHICLE_FUEL', label: 'Vehicle - Fuel', abbrv: 'Vehicle Fuel' },
  {
    value: 'VEHICLE_REPAIRS_MAINTENANCE',
    label: 'Vehicle - Repairs & Maintenance',
    abbrv: 'Vehicle Repairs',
  },
  {
    value: 'TAXES',
    label: 'Taxes - Capital gains, payroll, income and sales taxes',
    abbrv: 'Taxes',
  },
  {
    value: 'UNCATEGORIZED_EXPENSE',
    label: 'Uncategorized Expense',
    abbrv: 'Uncategorized Exp.',
  },
  { label: 'Cost of Goods', value: 'COST_OF_GOODS', abbrv: 'COGS' },
  { value: 'FOOD_COST', label: 'Food Cost', abbrv: 'Food Cost' },
  {
    value: 'PRODUCT_SAMPLES',
    label: 'Product Samples',
    abbrv: 'Prod. Samples',
  },
  {
    value: 'PURCHASES_PARTS_MATERIALS',
    label: 'Purchases - Parts & Materials',
    abbrv: 'Purch. Parts & Mat.',
  },
  {
    value: 'PURCHASES_RESALE_ITEMS',
    label: 'Purchases - Resale Items',
    abbrv: 'Purch. Resale Items',
  },
];

export default negativeCategories;
