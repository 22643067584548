import ReactGA from 'react-ga4';
import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import axiosInstance from '../../../api/axiosWrapper';
import { WebSocketContext } from '../../common/contexts/WebSocketContext';
import LinkAccountsPage from './LinkAccountsPage';
import { AiOutlineBank, AiOutlineCheckCircle } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { colors } from '../../../themes/theme';
import { GradientProgress } from '../../styles/style';
import CircularProgress from '@mui/material/CircularProgress';
import {
  StyledDialog,
  StyledDialogContent,
  StyledTitle,
  StyledSubText,
  StyledProgressText,
  IconWrapper,
} from './style';

const interactiveTexts = [
  { content: 'ONEBIT makes managing your finances as simple as pie! 🥧' },
  { content: 'We’re your financial buddy! 🤝 Let us handle the tough work.' },
  { content: 'Smarter decisions start with better insights. 📈' },
  { content: 'Track spending, expenses, and financial health effortlessly. 🔍' },
  { content: 'Monitor net profit and loss trends. 📉📈' },
  { content: 'Integrates with your POS systems for seamless management. 📊' },
  { content: 'Your finances are protected with encrypted data. 🛡️' },
];

const LinkBankAccountPage = () => {
  const [loadingData, setLoadingData] = useState(false);
  const [linkToken, setLinkToken] = useState(null);
  const [syncing, setSyncing] = useState(false);
  const [isWaitingForSync, setIsWaitingForSync] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [syncError, setSyncError] = useState(null);
  const [isFetchingToken, setIsFetchingToken] = useState(true);
  const [syncCompleted, setSyncCompleted] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [syncTimeout, setSyncTimeout] = useState(false);
  const [loadingActive, setLoadingActive] = useState(false);
  const [linkProcessStarted, setLinkProcessStarted] = useState(false);
  const { isConnected, wsRef } = useContext(WebSocketContext);
  const [progress, setProgress] = useState(0);
  const [progressCapped, setProgressCapped] = useState(false);
  const [currentText, setCurrentText] = useState(0);
  const [modalOpen, setModalOpen] = useState(true);

  // Fetch Plaid link token
  const fetchLinkToken = async () => {
    try {
      const { data } = await axiosInstance.post('/createPlaidLinkToken');
      if (data.success) {
        ReactGA.event({
          category: 'Plaid',
          action: 'Link Token Created',
        });
        setLinkToken(data.data.linkToken.link_token);
        localStorage.setItem('link_token', data.data.linkToken.link_token);
      } else {
        ReactGA.event({
          category: 'Plaid',
          action: 'Link Token Failed',
          label: 'API Error',
        });
        setFetchError('Failed to create Plaid link token. Please try again later.');
      }
    } catch (error) {
      ReactGA.event({
        category: 'Plaid',
        action: 'Link Token Error',
        label: error.message,
      });
      console.error('Error fetching link token:', error);
    }
  };

  // Simulated progress for syncing
  useEffect(() => {
    let interval;
    if (syncing && !syncCompleted) {
      ReactGA.event({
        category: 'Plaid',
        action: 'Sync Progress',
        value: progress,
      });
      interval = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 95) {
            setProgressCapped(true);
            clearInterval(interval);
            return 95;
          }
          let increment;
          if (oldProgress < 30) {
            increment = 4;
          } else if (oldProgress >= 30 && oldProgress < 90) {
            increment = 3;
          } else {
            increment = 1;
          }
          return Math.min(oldProgress + increment, 95);
        });
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [syncing, syncCompleted, progress]);

  // WebSocket to handle syncing process
  useEffect(() => {
    if (isConnected && wsRef.current) {
      const ws = wsRef.current;
      const handleMessage = (event) => {
        const message = JSON.parse(event.data);

        if (message.itemId !== currentItemId) return;

        if (message.status === 'syncing') {
          setSyncing(true);
          ReactGA.event({
            category: 'Plaid',
            action: 'Sync Started',
          });
          setIsWaitingForSync(false);
        } else if (message.status === 'complete') {
          ReactGA.event({
            category: 'Plaid',
            action: 'Sync Complete',
          });
          setSyncing(false);
          setSyncCompleted(true);
          setProgress(100);
        } else if (message.status === 'error') {
          setSyncing(false);
          setSyncError('An error occurred during the sync process. Please try again.');
        }
      };
      ws.addEventListener('message', handleMessage);     
       return () => ws.removeEventListener('message', handleMessage);
    }
  }, [isConnected, wsRef, currentItemId]);

  // Handle loadingActive behavior based on loadingData, syncing, and link process state
  useEffect(() => {
    if (loadingData && !syncing) {
      setLoadingActive(true);
    } else if (!loadingData && syncing) {
      setLoadingActive(true);
    } else if (!loadingData && !syncing && syncCompleted) {
      setLoadingActive(false);
    }
  }, [loadingData, syncing, syncCompleted]);

  useEffect(() => {
    if (syncing) {
      const textInterval = setInterval(() => {
        setCurrentText((prevText) => (prevText + 1) % interactiveTexts.length);
      }, 12000);
      return () => clearInterval(textInterval);
    }
  }, [syncing]);

  useEffect(() => {
    if (syncCompleted) {
      ReactGA.event({
        category: 'Plaid',
        action: 'Navigate to Dashboard',
      });
      setTimeout(() => {
        setModalOpen(false); 
        window.location.href = '/dashboard';
      }, 2000);
    }
  }, [syncCompleted]);
  
  const handleClose = () => {
    setModalOpen(false);
  };

  const onSyncStart = (itemId) => {
    setCurrentItemId(itemId);
    ReactGA.event({
      category: 'Plaid',
      action: 'Link Success',
    });
    setLoadingData(true);
    setLinkProcessStarted(true);
    setIsWaitingForSync(true);

  
    const timeoutId = setTimeout(() => {
      if (!syncing) {
        setSyncTimeout(true);
        setIsWaitingForSync(false);
      }
    }, 180000);

    return () => clearTimeout(timeoutId);
  };

  useEffect(() => {
    ReactGA.event({
      category: 'Plaid',
      action: 'Page Load',
    });
    const storedLinkToken = localStorage.getItem('link_token');
    if (storedLinkToken) {
      setLinkToken(storedLinkToken);
      setIsFetchingToken(false); 
    } else {
      fetchLinkToken();
    }
  }, []);

  if (isFetchingToken) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (fetchError || syncError) {
    return (
      <Container maxWidth="sm">
        <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
          <StyledTitle>Oops!</StyledTitle>
          <StyledSubText>{fetchError || syncError}</StyledSubText>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
            Please refresh the page or try again later. If the issue persists, contact support.
          </Typography>
        </Box>
      </Container>
    );
  }

  if (syncTimeout) {
    return (
      <Container maxWidth="sm">
        <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
          <StyledTitle>Timeout</StyledTitle>
          <StyledSubText>
            Syncing is taking longer than expected. Please exit and try again.
          </StyledSubText>
        </Box>
      </Container>
    );
  }

  return (
    <StyledDialog open={modalOpen} onClose={handleClose} fullWidth maxWidth="md">
      <StyledDialogContent>
          {linkToken &&
          !loadingActive &&
          !linkProcessStarted &&
          !syncCompleted &&
          !isWaitingForSync ? (
            <LinkAccountsPage
              loadPlaidWidget={true}
              key={linkToken}
              linkToken={linkToken}
              loadingData={loadingData}
              setLoadingData={setLoadingData}
              onSuccess={onSyncStart}
            />
          ) : !syncing && !syncCompleted ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              <CircularProgress color="primary" />
              <StyledProgressText>
                Waiting for syncing to start... Please hold on a moment.
              </StyledProgressText>
            </Box>
          ) : (
            !syncCompleted && (
              <Box display="flex" flexDirection="column" alignItems="center">
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ ease: 'linear', duration: 4, repeat: Infinity }}
                  style={{ marginBottom: '20px' }}
                >
                  <IconWrapper>
                    <AiOutlineBank size={60} color={colors.white} />
                  </IconWrapper>
                </motion.div>

                <StyledTitle>
                  {loadingData && !syncing && 'Preparing to sync your transactions...'}
                  {syncing && (
                    <>
                      {progress < 20 && 'We’re starting the sync process. Organizing your data now... '}
                      {progress >= 20 && progress < 50 && 'Progressing smoothly. Data is being sorted and organized... '}
                      {progress >= 50 && progress < 80 && 'Almost there! Insights are being calculated and your dashboard is updating... '}
                      {progress >= 80 && progress < 95 && 'Just about done! Verifying the final details for you... '}
                      {progress === 95 && !progressCapped && 'Finalizing the sync... Please allow us a few more moments to complete. '}
                      {progressCapped && !syncCompleted && 'Finalizing data sync. Large datasets may take a bit longer... '}
                    </>
                  )}
                </StyledTitle>

                <motion.div
                  key={currentText}
                  initial={{ opacity: 0, x: -100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 100 }}
                  transition={{ duration: 2 }}
                  style={{ textAlign: 'center', marginBottom: '10px' }}
                >
                  <StyledSubText>
                    {syncing
                      ? interactiveTexts[currentText].content
                      : 'This may take a few moments. Please do not close the browser.'}
                  </StyledSubText>
                </motion.div>

                <GradientProgress
                  variant="determinate"
                  value={progress}
                  sx={{ width: '100%', mt: 2 }}
                />
              </Box>
            )
          )}

          {syncCompleted && (
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: 'spring', stiffness: 120, duration: 0.8 }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                <AiOutlineCheckCircle size={80} color={colors.primary} />
                <StyledTitle>Transactions Synced!</StyledTitle>
              </motion.div>
            )}
            
        
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default LinkBankAccountPage;
