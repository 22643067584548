import React, { useState, useContext, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { SButton } from './style';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../../common/contexts/authContext';
import { colors } from '../../../themes/theme';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      sx={{
        marginTop: 2,
        fontSize: '0.8rem',
        color: colors.text,
      }}
    >
      {'Copyright © '}
      <Link color="inherit" href="#">
        ONEBIT
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const SignIn = ({
  user,
  setUser,
  handleSignUpClick,
  handleResetPasswordClick,
  errorMessage,
  setErrorMessage,
  buttonLoading,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ email: '', password: '' });
  const sButtonRef = useRef(null);
  const { login } = useContext(AuthContext);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (sButtonRef.current) {
        sButtonRef.current.click();
      }
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let formErrors = {};

    if (!user.emailId) {
      formErrors.email = 'Email is required';
    } else if (!validateEmail(user.emailId)) {
      formErrors.email = 'Invalid email address';
    }

    if (!user.password) {
      formErrors.password = 'Password is required';
    } else if (user.password.length < 8) {
      formErrors.password = 'Password must be at least 8 characters';
    }

    if (Object.keys(formErrors).length === 0) {
      try {
        await login(user);
      } catch (error) {
        setErrorMessage(error.message || 'Failed to log in. Please try again.');
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleError = () => {
    if (errorMessage) setErrorMessage('');
    setErrors({ email: '', password: '' });
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100%',
        padding: { xs: 2, sm: 4 },
        background: colors.SignInConGradient,
      }}
    >
      <Box
         sx={{
          background: colors.white,
          padding: { xs: 2, sm: 4 },
          borderRadius: 3,
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
          width: '100%',
          alignItems: 'left',
          maxWidth: '600px',
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          sx={{
            fontWeight: 'bold',
            background: colors.signInText,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textAlign: 'center',
            marginBottom: 1,
            textTransform: 'uppercase',
            fontSize: { xs: '1.5rem', sm: '2rem' },
          }}
        >
          Sign In
        </Typography>
        <Typography
          variant="body2"
          sx={{
            marginBottom: 2,
            textAlign: 'center',
            color: colors.text,
            fontSize: { xs: '0.9rem', sm: '0.95rem' },
          }}
        >
          Don't have an account yet?{' '}
          <Link
            href="#"
            onClick={handleSignUpClick}
            sx={{
              fontWeight: 'bold',
              color: colors.signUpText,
              cursor: 'pointer',
              '&:hover': { textDecoration: 'underline', color: '#0d47a1' },
            }}
            >
              Sign up
            </Link>
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleFormSubmit}
            onKeyDown={handleKeyDown}
            sx={{ width: '100%', }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="span" variant="h6" marginLeft={1}>
                  Email
                </Typography>
                <TextField
                  defaultValue={user.emailId}
                  required
                  fullWidth
                  id="email"
                  label="Enter your email"
                  name="email"
                  autoComplete="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  onChange={(event) => {
                    setUser({ ...user, emailId: event.target.value });
                    handleError();
                }}
                  sx={{
                    marginBottom: 1,
                    mt: 1.2,
                    background: colors.lightGray2,
                    borderRadius: 1,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="span" variant="h6"  marginLeft={1}>
                  Password
                </Typography>
                <TextField
                  required
                  fullWidth
                  id="password"
                  label="Enter your password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  autoComplete="new-password"
                  error={Boolean(errors.password)}
                  helperText={errors.password}
                  onChange={(event) => {
                    setUser({ ...user, password: event.target.value });
                    handleError();
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    marginBottom: 2,
                    mt: 1.2,
                    background: colors.lightGray2,
                    borderRadius: 1,
                  }}
                />
                <Link
                  variant="body2"
                  sx={{
                    display: 'block',
                    marginBottom: 2,
                    color: colors.signUpText,
                    cursor: 'pointer',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                  onClick={handleResetPasswordClick}
                >
                  Forgot your password?
                </Link>
              </Grid>
            </Grid>
            {errorMessage && (
              <Alert
                severity="error"
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  borderRadius: 1,
                  fontSize: '0.9rem',
                }}
              >
                {errorMessage}
              </Alert>
            )}
            <SButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                marginTop: 3,
                padding: 1.5,
                fontSize: '1rem',
                fontWeight: 'bold',
                backgroundColor: '#1565C0',
                '&:hover': { backgroundColor: '#0d47a1' },
              }}
              disabled={buttonLoading}
              ref={sButtonRef}
              startIcon={
                buttonLoading ? <CircularProgress size={20} color="inherit" /> : null
              }
            >
              {buttonLoading ? '' : 'Sign In'}
            </SButton>
          </Box>
          <Copyright sx={{ marginTop: 4 }} />
        </Box>
      </Container>
  );
};

export default SignIn;
