import React, { useEffect } from 'react';
import { Alert, Snackbar, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const StyledAlert = styled(Alert)(({ theme }) => ({
    backgroundColor: '#FFFAEB', // Light warm background
    color: '#B54708', // Warm brown text
    border: '1px solid #FEC84B', // Subtle yellow border
    '& .MuiAlert-icon': {
        color: '#F79009', // Warning amber color
        padding: '0px 0',
        marginRight: '4px',
        '& .warning-icon': {
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center'
        }
    },
    '& .MuiAlert-action': {
        padding: '0px',
        marginRight: '-4px'
    },
    '& .MuiAlert-message': {
        padding: '0px',
        fontSize: '12px',
        fontWeight: 500  // Slightly bolder text
    },
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
    padding: '4px 8px',
    minHeight: '28px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.2s ease-in-out'  // Smooth transition for any changes
}));

const NavigationWarning = ({ isEditing, editingCellId, onClose }) => {
    const [open, setOpen] = React.useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpen(false);
        onClose?.(); // Call the onClose prop when closing
    };

    useEffect(() => {
        if (isEditing) {
            setOpen(true);
        }
    }, [isEditing]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (isEditing) {
                e.preventDefault();
                e.returnValue = 'Changes that you made may not be saved.';
                return e.returnValue;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [isEditing]);


    return (
        <Snackbar
            open={isEditing && open}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{
                position: 'fixed', 
                top: '8px !important',
                left: '50% !important',
                transform: 'translateX(-50%) !important',
                right: 'auto !important',
                zIndex: 1400,
                width: 'auto',
                '& .MuiSnackbarContent-root': {
                    width: 'auto',
                    minWidth: 'auto'
                }
            }}
        >
            <StyledAlert
                icon={<span className="warning-icon" role="img" aria-label="warning">⚠️</span>}
                severity="warning"
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            color: '#B54708',
                            padding: '2px',
                            '&:hover': {
                                backgroundColor: 'rgba(181, 71, 8, 0.08)'
                            }
                        }}
                    >
                        <CloseIcon sx={{ fontSize: '14px' }} />
                    </IconButton>
                }
                sx={{
                    width: 'auto',
                    minWidth: 'auto',
                    '&:hover': {
                        backgroundColor: '#FFF8E1'  // Slightly darker on hover
                    }
                }}
            >
                Editing in Progress - Please save or discard your changes
            </StyledAlert>
        </Snackbar>
    );
};

export default NavigationWarning;