import React from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import CustomTooltip from './CustomTooltip';
import { colors } from '../../../../themes/theme';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

const BarChartBars = ({ data }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        width: '100%',
        overflowX: 'auto', // Enable horizontal scrolling for small screens
        overflowY: 'hidden',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': { height: '8px' },
        '&::-webkit-scrollbar-thumb': { background: colors.lightGray3, borderRadius: '4px' },
        '&::-webkit-scrollbar-thumb:hover': { background: colors.text },
      }}
    >
        <ResponsiveContainer
          width="100%"
          height={isSmallScreen ? 200 : 250}  
        >
          <BarChart
            data={data}
            margin={{
              top: isSmallScreen ? 8 : 16,
              right: isSmallScreen ? 10 : 20,
              left: isSmallScreen ? 5 : 10,
              bottom: isSmallScreen ? 10 : 20,
            }}
            barCategoryGap={isSmallScreen ? '20%' : '40%'} 
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              tick={{ fontSize: isSmallScreen ? 10 : 14, fill: colors.mediumGray }}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              tickFormatter={(value) => `$${Math.abs(value / 1000).toFixed(1)}K`}
              tick={{ fontSize: isSmallScreen ? 10 : 14, fill: colors.mediumGray }}
              axisLine={false}
              tickLine={false}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="netAmount" radius={[4, 4, 0, 0]}>
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.netAmount >= 0 ? colors.income : colors.expenses}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
    </Box>
  );
};

export default BarChartBars;
