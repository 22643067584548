import React, { useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { SButton } from '../Login/style';
import Alert from '@mui/material/Alert';
import SubmitPasswordForm from '../../common/SubmitPasswordForm';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../../common/contexts/authContext';
import { colors } from '../../../themes/theme';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      sx={{
        marginTop: 2,
        fontSize: '0.8rem',
        color: colors.text,
      }}
    >
      {'By signing up, you are indicating that you have read and agree to the '}
      <Link href="./termsOfUse.html" underline="hover" color="primary">
        Terms of Use
      </Link>{' '}
      {' and '}
      <Link href="./privPolicy.html" underline="hover" color="primary">
        Privacy Policy
      </Link>
      {'.'}
    </Typography>
  );
}

const SignUp = ({
  registerUser,
  setRegisterUser,
  handleSignInClick,
  errorMessage,
  setErrorMessage,
  buttonLoading,
}) => {
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    letter: false,
    number: false,
    specialChar: false,
    validChars: true,
  });

  const { register } = useContext(AuthContext);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const criteria = {
      length: password.length >= 8,
      letter: /[A-Za-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*]/.test(password),
      validChars: /^[A-Za-z\d!@#$%^&*]*$/.test(password),
    };
    setPasswordCriteria(criteria);
    const strength =
      Object.values(criteria).reduce((acc, curr) => acc + (curr ? 1 : 0), 0) *
      20;
    setPasswordStrength(strength);
    return (
      criteria.length &&
      criteria.letter &&
      criteria.number &&
      criteria.specialChar &&
      criteria.validChars
    );
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let formErrors = {};

    if (!registerUser.emailId) {
      formErrors.email = 'Email is required';
    } else if (!validateEmail(registerUser.emailId)) {
      formErrors.email = 'Invalid email address';
    }

    if (!registerUser.password) {
      formErrors.password = 'Password is required';
    } else if (!validatePassword(registerUser.password)) {
      formErrors.password = 'Password does not meet the required criteria.';
    }

    if (!registerUser.confirmPassword) {
      formErrors.confirmPassword = 'Please confirm your password';
    } else if (registerUser.password !== registerUser.confirmPassword) {
      formErrors.confirmPassword = 'Passwords do not match';
    }

    if (Object.keys(formErrors).length === 0) {
      try {
        await register(registerUser);
      } catch (error) {
        setErrorMessage(
          error.message || 'Failed to register. Please try again.'
        );
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleError = () => {
    if (errorMessage) setErrorMessage('');
    setErrors({ email: '', password: '', confirmPassword: '' });
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        minHeight: '100%',
        padding: { xs: 2, sm: 4 },
        background: colors.SignInConGradient,
      }}
    >
      <Box
         sx={{
          background: colors.white,
          padding: { xs: 2, sm: 4 },
          borderRadius: 3,
          flexDirection: 'column',
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
          textAlign: 'left',
          width: '100%',
          maxWidth: { xs: '100%', sm: '500px' }
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            background: colors.signInText,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            marginBottom: 1,
            textTransform: 'uppercase',
            fontSize: { xs: '1.5rem', sm: '2rem' },
          }}
        >
          Create Account
        </Typography>
        <Typography
          variant="body2"
          sx={{
            marginBottom: 2,
            textAlign: 'center',
            color: '#555',
            fontSize: { xs: '0.9rem', sm: '0.95rem' },
          }}
        >
          Already have an account?{' '}
          <Link
            href="#"
            onClick={handleSignInClick}
            sx={{
              fontWeight: 'bold',
              color: colors.signUpText, 
              cursor: 'pointer',
              '&:hover': { textDecoration: 'underline', color: '#0d47a1' },
            }}
          >
            Sign in
          </Link>
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleFormSubmit}
          sx={{  width: '100%' }}
        >
          <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="span" variant="h6"  marginLeft={1}>
                  Email
                </Typography>
                <TextField
                  sx={{ mt: 1.2 }}
                  required
                  fullWidth
                  id="email"
                  label="Enter your email"
                  name="email"
                  autoComplete="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  onChange={(event) => {
                    setRegisterUser({
                      ...registerUser,
                      emailId: event.target.value,
                    });
                    handleError();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <SubmitPasswordForm
                  handleError={handleError}
                  errors={errors}
                  registerUser={registerUser}
                  setRegisterUser={setRegisterUser}
                  validatePassword={validatePassword}
                  passwordStrength={passwordStrength}
                  passwordCriteria={passwordCriteria}
                />
              </Grid>
            </Grid>
            {errorMessage && (
              <Alert severity="error" sx={{ mt: 3 }}>
                {errorMessage}
              </Alert>
            )}
            <SButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            disabled={buttonLoading} 
            startIcon={buttonLoading ? <CircularProgress size={20} /> : null} 
          >
            {buttonLoading ? '' : 'Get Started'} 
          </SButton>
          </Box>
        </Box>
        <Copyright sx={{ mt: 3 }} />
      </Container>
  );
};
export default SignUp;
