import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PlaidLink from './PlaidLink';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import { colors } from '../../../themes/theme';
import Lottie from 'react-lottie-player';
import loadingAnimation from '../../../assets/animations/loading.json';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SecurityIcon from '@mui/icons-material/Security';

const LinkAccountsPage = ({
  loadingData,
  setLoadingData,
  linkToken,
  onSuccess,
  onExit,
}) => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        width: '100%',
        maxWidth: '600px',
        maxHeight: 'calc(100vh - 60px)', 
        overflowY: 'auto', 
        borderRadius: '20px',
        backgroundColor: 'transparent',
        padding: { xs: '15px', sm: '30px' }, 
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Lottie
          loop
          animationData={loadingAnimation}
          play
          style={{ width: '250px', height: '80px' }} 
        />
      </Box>

      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          color: colors.darkGray3,
          marginBottom: { xs: '15px', sm: '20px' },
          textTransform: 'uppercase',
          fontSize: { xs: '1.3rem', sm: '2rem' }, 
        }}
        role="heading"
      >
        Link Your Accounts
      </Typography>

      <Typography
        variant="body1"
        sx={{
          color: colors.mediumGray,
          marginBottom: { xs: '15px', sm: '20px' },
          lineHeight: '1.8',
          fontSize: { xs: '0.85rem', sm: '1rem' }, 
        }}
      >
        Securely connect with your bank accounts using{' '}
        <Link
          href="https://plaid.com"
          target="_blank"
          rel="noopener"
          sx={{
            color: colors.primary,
            textDecoration: 'none',
            fontWeight: 'bold',
            '&:hover': { textDecoration: 'underline' },
          }}
          aria-label="Learn more about Plaid"
        >
          Plaid
        </Link>
        . <br />
        <Link
          href="https://onebitapp.com"
          target="_blank"
          rel="noopener"
          sx={{
            color: colors.primary,
            textDecoration: 'none',
            fontWeight: 'bold',
            '&:hover': { textDecoration: 'underline' },
          }}
          aria-label="Learn more about ONEBIT"
        >
          ONEBIT
        </Link>{' '}
        leverages Plaid to provide insights into your business's expenses and
        transactions.
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' }, 
          gap: '15px', 
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            flex: 1,
            padding: '10px',
            background: 'rgba(66, 165, 245, 0.1)',
            borderRadius: '10px',
          }}
        >
          <AccountBalanceIcon
            sx={{
              color: colors.primary,
              fontSize: { xs: '30px', sm: '40px' }, 
              marginBottom: '10px',
            }}
          />
          <Typography
            sx={{
              fontSize: { xs: '0.9rem', sm: '1rem' }, 
              fontWeight: 'bold',
            }}
          >
            Seamless Transactions
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '0.8rem', sm: '0.9rem' },
              color: colors.mediumGray,
              lineHeight: '1.6',
            }}
          >
            Monitor your business's finances effortlessly.
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            flex: 1,
            padding: '10px',
            background: 'rgba(66, 165, 245, 0.1)',
            borderRadius: '10px',
          }}
        >
          <SecurityIcon
            sx={{
              color: colors.primary,
              fontSize: { xs: '30px', sm: '40px' },
              marginBottom: '10px',
            }}
          />
          <Typography
            sx={{
              fontSize: { xs: '0.9rem', sm: '1rem' },
              fontWeight: 'bold',
            }}
          >
            Secure and Private
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '0.8rem', sm: '0.9rem' },
              color: colors.mediumGray,
              lineHeight: '1.6',
            }}
          >
            Your data is protected with bank-level encryption.
          </Typography>
        </Box>
      </Box>

      <Typography
        variant="body2"
        sx={{
          color: colors.lightGray4,
          marginBottom: { xs: '15px', sm: '30px' },
          lineHeight: '1.6',
          fontSize: { xs: '0.8rem', sm: '0.95rem' },
        }}
      >
          By linking your accounts, you can gain a better understanding of your
          financial health and make more informed business decisions.
        </Typography>
        <Box sx={{ marginTop: '30px' }}>
        {linkToken ? (
          <PlaidLink
            autoFocus
            loadingData={loadingData}
            setLoadingData={setLoadingData}
            linkToken={linkToken}
            onSyncStart={onSuccess} 
            onExit={onExit}
          />
        ) : (
          <CircularProgress color="primary" />
        )}
      </Box>
    </Box>
  );
};

export default LinkAccountsPage;
