import React from 'react';
import { GridActionsCellItem, GridRowModes } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Tooltip } from '@mui/material';
import {
  UNCATEGORIZED_INCOME,
  UNCATEGORIZED_EXPENSE,
} from '../../common/categories';
import { CategorySelect } from './customCategorySelect';
import { colors } from '../../../themes/theme';
import { addCommasToNumber } from '../Reports/addCommasToNumber';

export const getGridColumns = ({
  accounts,
  handleEditClick,
  handleSaveClick,
  handleCancelClick,
  handleDeleteClick,
  handleUploadReceiptClick,
  rowModesModel,
  validateRow,
  isPrintMedia,
  transactionCategoryColorMap,
  categories,
  editingCellId,
}) => [
    {
      field: 'date',
      headerName: 'Date',
      type: 'date',
      width: 120,
      editable: true,
      getApplyQuickFilterFn: (value) => {
        if (!value) {
          return null;
        }
        return (params) => {
          return params.formattedValue.startsWith(value);
        };
      },
      preProcessEditCellProps: (params) => validateRow(params),
      valueGetter: ({ value }) => new Date(value),
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        });
      },
      renderCell: (params) => {
        return (
          <Tooltip title={params.formattedValue} placement="left" arrow>
            <div className="MuiDataGrid-cellContent" role="presentation">
              {params.formattedValue}
            </div>
          </Tooltip>
        );
      },
  },
  {
    field: 'description',
    headerName: 'Description',
    ...(isPrintMedia ? { width: 200 } : { flex: 1 }),
    minWidth: 200,
    editable: true,
    preProcessEditCellProps: (params) => validateRow(params),
    cellClassName: 'print-cell-fw',
    renderCell: (params) => {
      const isPending = params.row.isPending;
      const pendingLabel = isPending ? (
        <span style={{
          color: '#999999',
          fontFamily: 'serif0',
          fontWeight: 'bold',
          fontSize: '0.8rem',
          marginRight: '8px',
        }}>
          (Pending)
        </span>
      ) : null;
  
      return (
        <Tooltip title={params.formattedValue} placement="right" arrow>
          <div className="MuiDataGrid-cellContent" role="presentation" style={{ display: 'flex', alignItems: 'center' }}>
            {pendingLabel}
            <span>{params.formattedValue}</span>
          </div>
        </Tooltip>
      );
    },
  }, 
  {
    field: 'account',
    headerName: 'Account',
    minWidth: 150,
    editable: false,
    type: 'singleSelect',
    valueOptions: accounts.map((account) => account.name),
    preProcessEditCellProps: (params) => validateRow(params),
    renderCell: (params) => (
      <Tooltip title={params.formattedValue} placement="left" arrow>
        <div className="MuiDataGrid-cellContent" role="presentation">
          {params.formattedValue}
        </div>
      </Tooltip>
    ),
  },
  {
    field: 'category',
    headerName: 'Category',
    minWidth: 200,
    editable: true,
    type: 'singleSelect',
    valueOptions: (params) => {
      const { incomeCategories, expenseCategories, allCategories } = categories || {};
      const cb = (cat) => ({ value: cat.value, label: cat.label });
      if (params.row?.amount > 0) { // Safe check for undefined amount
        return incomeCategories.map(cb);
      } else if (params.row?.amount < 0) {
        return expenseCategories.map(cb);
      } else {
        return allCategories.map(cb);
      }
    },
    getApplyQuickFilterFn: (value) => {
      if (!value) return null;
      return (params) => params.value?.toLowerCase().includes(value.toLowerCase());
    },
    renderEditCell: (params) => {
      return (
        <CategorySelect
          categories={categories}
          value={params.value}
          onChange={(event) => {
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event.target.value,
            });
          }}
          row={params.row}
        />
      );
    },
    valueGetter: (params) => params.row.category,
    valueSetter: (params) => {
      const { value, row } = params;
      row.category = value;
      return { ...row, category: value };
    },
    preProcessEditCellProps: (params) => validateRow(params),
    renderCell: (params) => {
      let category = params.value;
      if (!category) {
        category =
          params.row.amount > 0 ? UNCATEGORIZED_INCOME : UNCATEGORIZED_EXPENSE;
      }
  
      const color = transactionCategoryColorMap[category] ?? colors.lightGray2;
      const categoryLabel =
        categories.allCategories.find((cat) => cat.value === params.value)?.label || params.value;
  
      return (
        <Tooltip title={categoryLabel} placement="right" arrow>
          <div
            className="MuiDataGrid-cellContent"
            role="presentation"
            style={{
              backgroundColor: color,
              padding: '2px 12px',
              borderRadius: '50px',
            }}
          >
            {categoryLabel}
          </div>
        </Tooltip>
      );
    },
  },  
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 150,
    type: 'number',
    editable: true,
    getApplyQuickFilterFn: (value) => {
      if (!value) {
        return null;
      }
      // Check if 'value' contains negative numbers (like "-123")
      if (/-\d+/.test(value)) {
        // For every negative number found, prefix it with a dollar sign (turn "-123" into "-$123")
        value = value.replace(/-(\d+)/g, `-$$$1`);
      }
      return (params) => {
        return params.formattedValue.includes(value);
      };
    },
    preProcessEditCellProps: (params) => validateRow(params),
    valueFormatter: (params) => {
      const value = params.value;
      const formattedValue = `$${addCommasToNumber(Math.abs(value).toFixed(2))}`;
      return value < 0 ? `-${formattedValue}` : formattedValue;
      },
      valueSetter: (params) => {
        const { value, row } = params;
        row.amount = value;
        if (
          row.amount < 0 &&
          categories?.incomeCategories?.find((cat) => cat.value === row.category)
        ) {
          row.category = UNCATEGORIZED_EXPENSE;
        } else if (
          row.amount > 0 &&
          categories?.expenseCategories?.find((cat) => cat.value === row.category)
        ) {
          row.category = UNCATEGORIZED_INCOME;
        }
        return { ...row, amount: value };
      },
      renderCell: (params) => {
        return (
          <Tooltip title={params.formattedValue} placement="left" arrow>
            <div
              className="MuiDataGrid-cellContent"
              role="presentation"
              style={{ color: params.value < 0 ? '#f22233' : '#03a63c' }}
            >
              {params.formattedValue}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 140,
      cellClassName: 'actions no-print',
      renderHeader: (params) => {
        const hasEditingRows = Object.values(rowModesModel).some(
          (mode) => mode.mode === GridRowModes.Edit
        );
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            fontWeight: hasEditingRows ? 700 : 400
          }}>
            {params.colDef.headerName}
            {hasEditingRows && (
              <Tooltip title="One or more rows are being edited" arrow>
                <div
                  style={{
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: '#f22233',
                    marginLeft: '4px',
                  }}
                />
              </Tooltip>
            )}
          </div>
        );
      },
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
        if (isInEditMode) {
          return [
            <Tooltip
              title={row.receiptUrl ? 'View Receipt' : 'Upload Receipt'}
              arrow
            >
             <GridActionsCellItem
                icon={row.receiptUrl ? <ReceiptIcon /> : <FileUploadIcon />}
                label={row.receiptUrl ? 'View Receipt' : 'Upload Receipt'}
                onClick={() => handleUploadReceiptClick(id)}
                sx={{color: colors.darkGray3}}
              />
            </Tooltip>,
            <Tooltip title="Save Transaction" arrow>
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)}
                sx={{
                  color: '#15803d',
                  '&:hover': {
                    color: '#166534',
                  },
                }}
              />
            </Tooltip>,
            <Tooltip title="Cancel Edit" arrow>
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                onClick={() => handleCancelClick(id)} 
                sx={{
                  color: '#f22233',
                  '&:hover': {
                    color: '#d41e2d',
                  },
                }}
              />
            </Tooltip>,
          ];
        }
  
        return [
          <Tooltip
            title={row.receiptUrl ? 'View Receipt' : 'Upload Receipt'}
            arrow
          >
            <GridActionsCellItem
              icon={row.receiptUrl ? <ReceiptIcon /> : <FileUploadIcon />}
              label={row.receiptUrl ? 'View Receipt' : 'Upload Receipt'}
              onClick={() => handleUploadReceiptClick(id)}
              color="primary"
            />
          </Tooltip>,
          <Tooltip title="Edit Transaction" arrow>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEditClick(id)} 
              color="primary"
            />
          </Tooltip>,
          <Tooltip title="Delete Transaction" arrow>
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDeleteClick(id)} 
              color="primary"
            />
          </Tooltip>,
        ];
      },
    },
  ];