import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, TextField, Button, Divider, CircularProgress, Box } from '@mui/material';
import { colors } from '../../../../../themes/theme';

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  dialogType = 'item',
  itemName = '',
  confirmationAction = 'unlink',
  verificationText,
  setVerificationText,
}) => {
  const [loading, setLoading] = useState(false);

  const handleConfirmClick = async () => {
    setLoading(true);
    try {
      await onConfirm(); 
    } catch (error) {
      console.error("Error during confirmation:", error);
    } finally {
      setLoading(false); 
    }
  };

  const getConfirmationMessage = () => {
    const itemType = dialogType === 'account' ? 'Account' : 'Bank Item';
    const actionText =
      confirmationAction === 'unlink'
        ? 'permanently unlink'
        : 'deactivate';
    const removalDetails =
      dialogType === 'account'
        ? 'all associated transactions, reports, and metrics for this account.'
        : 'all accounts, transactions, reports, and metrics associated with this bank item.';
    return (
      <Box sx={{ textAlign: 'center', mt: 1 }}> 
        <Typography variant="body1" sx={{ fontSize: { xs: '1rem', md: '1.25rem' }, color: colors.textSecondary }}>
          Are you sure you want to {actionText} the {itemType}{' '}
          <Typography
            component="span"
            sx={{
              color: colors.primaryDark,
              fontWeight: 'bold',
              fontSize: { xs: '0.95rem', md: '1.1rem' },
              display: 'inline-block',
              mx: 0.5,
            }}
          >
            "{itemName}"
          </Typography>?
        </Typography>
        <Typography
          sx={{
            color: colors.textSecondary,
            fontSize: { xs: '0.8rem', md: '1rem' },
            lineHeight: 1.6,
            mt: 1,
            textAlign: 'center' 
          }}
        >
          This action will {confirmationAction === 'unlink' ? 'permanently remove' : 'temporarily disable'} {removalDetails}
        </Typography>
      </Box>
    );
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '15px',
          padding: { xs: '10px', md: '20px' },
          width: { xs: '90%', sm: '85%' },
          maxWidth: { xs: '100%', sm: '400px', md: '600px' },
          boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
          backgroundColor: colors.lightGray,
          overflow: 'hidden',
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          fontWeight: 'bold', 
          fontSize: { xs: '1.15rem', md: '2rem' },
          color: colors.black, 
          mb: -2,
          textAlign: 'center',
        }}
      >
        Confirm {confirmationAction === 'unlink' ? 'Unlink' : 'Deactivation'}
      </DialogTitle>
      <Divider/>
      <DialogContent sx={{ paddingBottom: 0 }}>
        {getConfirmationMessage()}
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          value={verificationText}
          onChange={(e) => setVerificationText(e.target.value)}
          placeholder="Type 'delete' to confirm"
          InputProps={{
            sx: {
              backgroundColor: colors.white,
              borderRadius: '8px',
              mt: 3,
              '& input': {
                padding: '12px', 
              },
            }
          }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: 2, mt: 3 }}>
        <Button 
          onClick={onClose} 
          sx={{ 
            textTransform: 'none', 
            color: colors.primary, 
            fontWeight: 'bold', 
            padding: { xs: '6px 14px', md: '10px 24px' },
            borderRadius: '20px',
            fontSize: { xs: '0.85rem', md: '1rem' },
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: colors.primaryHover,
              color: colors.white,
            }
          }}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirmClick}
          disabled={verificationText.toLowerCase() !== 'delete' || loading}
          sx={{
            textTransform: 'none',
            fontWeight: 'bold',
            padding: { xs: '6px 14px', md: '10px 24px' },
            borderRadius: '20px',
            fontSize: { xs: '0.85rem', md: '1rem' },
            backgroundColor: verificationText.toLowerCase() === 'delete' ? colors.error : colors.lightError,
            color: verificationText.toLowerCase() === 'delete' ? colors.white : colors.textSecondary,
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: verificationText.toLowerCase() === 'delete' && !loading ? colors.error : colors.lightError,
            },
          }}
        >
          {loading ? <CircularProgress size={20} color="inherit" /> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
