import ReactGA from 'react-ga4';
import React, { useContext, useState, useEffect } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import ProfileInfo from './ProfileInfo';
import BankAccountInfo from './BankAccountPage/BankAccountInfo';
import { colors } from '../../../../themes/theme';
import { AuthContext } from '../../../../components/common/contexts/authContext';
import { AccountCircle } from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const SettingsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedTab, setSelectedTab] = useState(() => {
    if (location.pathname.includes('profile')) return 0;
    if (location.pathname.includes('bank-account')) return 1;
    return 0;
  });

  useEffect(() => {
    ReactGA.event({
      category: 'Settings',
      action: 'Page View',
      label: getPageTitle(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleTabChange = (event, newValue) => {
    const tabName = newValue === 0 ? 'Profile' : 'Bank Account';
    ReactGA.event({
      category: 'Settings',
      action: 'Tab Change',
      label: tabName,
    });
    setSelectedTab(newValue);
    if (newValue === 0) {
      navigate('/settings/profile');
    } else if (newValue === 1) {
      navigate('/settings/bank-account');
    }
  };

  const getPageTitle = () => {
    switch (location.pathname) {
      case '/settings/profile':
        return 'Profile Information';
      case '/settings/bank-account':
        return 'Bank Accounts';
      default:
        return 'Settings';
    }
  };

  if (!isLoggedIn) {
    ReactGA.event({
      category: 'Settings',
      action: 'Unauthorized Access Attempt',
    });
    return <Navigate to="/login" />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
        backgroundColor: colors.lightGray,
        padding: '10px',
      }}
    >
      {!isSmallScreen && (
        <Tabs
          orientation="vertical"
          value={selectedTab}
          onChange={handleTabChange}
          sx={{
            minWidth: '280px',
            backgroundColor: colors.lightGray,
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
            padding: '10px',
            borderColor: 'divider',
            '& .MuiTabs-indicator': {
              display: 'none',
            },
            '& .MuiTab-root': {
              borderRadius: '10px',
              fontWeight: '600',
              fontSize: '1.05rem',
              color: selectedTab === -1 ? colors.white : colors.black,
              display: 'flex',
              alignItems: 'center',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: colors.gray,
                color: colors.black,
              },
              '&.Mui-selected': {
                backgroundColor: colors.primary,
                color: colors.white,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
              },
            },
          }}
        >
          <Tab
            icon={<AccountCircle fontSize="medium" />}
            iconPosition="start"
            label="Profile"
            sx={{
              justifyContent: 'flex-start',
              textTransform: 'none',
              marginBottom: 2,
              marginTop: 2, 
            }}
          />
          <Tab
            icon={<AccountBalanceIcon fontSize="medium" />}
            iconPosition="start"
            label="Bank Account"
            sx={{
              justifyContent: 'flex-start',
              textTransform: 'none',
              marginBottom: 2,
            }}
          />
        </Tabs>
      )}
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: colors.white,
          borderRadius: '12px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
          padding: '25px',
          marginLeft: isSmallScreen ? 0 : '15px',
          width: isSmallScreen ? '100%' : 'calc(100% - 300px)',
        }}
      >
        {location.pathname.startsWith('/settings') && (
          <Typography variant="h4" gutterBottom sx={{ fontWeight: '700' }}>
            {getPageTitle()}
          </Typography>
        )}
        <Routes>
          <Route path="/" element={<Navigate to="profile" />} />
          <Route path="profile" element={<ProfileInfo />} />
          <Route path="bank-account" element={<BankAccountInfo />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default SettingsPage;
