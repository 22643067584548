import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Menu,
  Button,
  Box,
  Switch,
} from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HomeIcon from '@mui/icons-material/Home';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SavingsIcon from '@mui/icons-material/Savings';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { colors } from '../../../../../themes/theme';

const BankAccountCard = ({
  bank,
  setSnackbarMessage,
  setSnackbarSeverity,
  setOpenSnackbar,
  handleOpenDialog,
  handleToggleAccount,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (accountId, itemId, accountName, isActive) => {
    handleToggleAccount(accountId, itemId, accountName, !isActive);
  };

  const getAccountIcon = (subtype) => {
    switch (subtype) {
      case 'checking':
        return <HomeIcon sx={{ color: colors.white }} />;
      case 'savings':
        return <SavingsIcon sx={{ color: colors.white }} />;
      case 'credit card':
        return <CreditCardIcon sx={{ color: colors.white }} />;
      default:
        return <AccountBalanceIcon sx={{ color: colors.white }} />;
    }
  };

  return (
    <Card
    sx={{
        mb: 4,
        maxWidth: { xs: '100%', sm: '700px' },
        mx: 'auto',
        borderRadius: '15px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        background: `linear-gradient(185deg, ${colors.white}, ${colors.receiptButtonGrey}, ${colors.white})`,
        overflow: 'hidden',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease', 
        '&:hover': {
            transform: 'scale(1.02)',
            boxShadow: '0 6px 24px rgba(0, 0, 0, 0.15)',
        },
    }}
    >
      <CardContent sx={{ padding: { xs: '16px', md: '24px 32px' } }}>
        {/* Bank Header with Menu Icon */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
            background: `linear-gradient(120deg, ${colors.primaryDark}, ${colors.primaryHover})`,
            padding: '20px',
            borderRadius: '12px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AccountBalanceIcon
              sx={{ fontSize: { xs: 24, md: 30 }, color: colors.white, mr: 1 }}
            />
            <Typography
              variant="h6"
              sx={{
                fontWeight: 800,
                color: colors.white,
                fontSize: { xs: '1.3rem', md: '1.5rem' },
              }}
            >
              {bank.institutionName}
            </Typography>
          </Box>
          <IconButton onClick={handleMenuOpen} aria-label="more options">
            <MoreVertIcon sx={{ color: colors.white }} />
          </IconButton>
          <Menu
             anchorEl={anchorEl}
             open={Boolean(anchorEl)}
             onClose={handleMenuClose}
             disablePadding
             anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
             transformOrigin={{ vertical: 'top', horizontal: 'left' }}
             slotProps={{
                 paper: {
                     sx: {
                         backgroundColor: 'transparent',
                         boxShadow: 'none',
                         padding: 0,
                         minWidth: 'auto',
                         overflow: 'visible',
                         mt: '-8px',
                     },
                 },
             }}
          >
            {/* Unlink whole Bank Item */}
           <Button
            onClick={() => {
                handleMenuClose();
                handleOpenDialog('item', null, bank.id, bank.institutionName);
            }}
            startIcon={<LinkOffIcon />}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: colors.error,
                fontWeight: 'bold',
                borderRadius: '10px',
                padding: '6px 12px',
                gap: 0.5,
                minWidth: 'auto',
                backgroundColor: colors.lightGray2,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 1.2)', 
                transition: 'all 0.3s',
                '&:hover': {
                    backgroundColor: colors.errorBg,
                    color: colors.error,
                    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)', 
                },
                '&:active': {
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',  
                    transform: 'scale(0.98)',
                },
                fontSize: { xs: '0.85rem', md: '0.9rem' },
            }}
            aria-label="Unlink Bank Item"  
            >
            Unlink
            </Button>
          </Menu>
        </Box>

        {/* Divider */}
        <Divider
          sx={{
            my: 2,
            background: `linear-gradient(90deg, ${colors.primaryHover}, ${colors.primaryDark})`,
            height: '2px',
            borderRadius: '1px',
          }}
        />

        {/* Account List */}
        <List disablePadding>
          {bank.accounts.map((account) => (
            <ListItem
              key={account.id}
              sx={{
                py: 1.5,
                px: 1,
                flexDirection: { sm: 'row' },
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: { xs: 'flex-start', sm: 'center' }, 
                opacity: account.is_active ? 1 : 0.4, 
                backgroundColor: account.is_active ? 'none' : colors.lightGray2,
                borderRadius: '12px',
                mb: 1,
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
              }}
            >
              {/* Account Details */}
              <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <ListItemIcon
                  sx={{
                    minWidth: 'auto',
                    mr: 2,
                    background: `linear-gradient(90deg, ${colors.primary}, ${colors.primaryHover})`,
                    borderRadius: '50%',
                    p: 1.5,
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  {getAccountIcon(account.subtype)}
                </ListItemIcon>
                <ListItemText
                    // Account Name and Balance
                    primary={
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: '0.9rem', md: '1rem' },
                    color: account.is_active ? colors.black : colors.textSecondary,
                  }}
                >
                  {account.name}
                </Typography>
              }
              secondary={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    flexWrap: 'wrap',
                  }}
                >
                  <Typography
                    sx={{
                      color: colors.textSecondary,
                      fontSize: { xs: 12, md: 14 },
                    }}
                  >
                    {account.subtype.charAt(0).toUpperCase() +
                      account.subtype.slice(1)}
                  </Typography>
                  <Typography
                    sx={{
                      color: colors.textSecondary,
                      fontSize: { xs: 12, md: 14 },
                    }}
                  >
                    |
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: { xs: '0.8rem', md: '0.9rem' },
                      color: account.is_active ? colors.success : colors.textSecondary,
                    }}
                  >
                    Balance: $
                    {account.balances?.available !== null
                      ? account.balances.available.toFixed(2)
                      : account.balances.current.toFixed(2)}
                  </Typography>
                  {!account.is_active && (
                    <Typography
                      sx={{
                        fontSize: { xs: '0.8rem', md: '0.9rem' },
                        color: colors.error,
                        fontWeight: 'bold',
                      }}
                    >
                      (Deactivated)
                    </Typography>
                  )}
                </Box>
              }
              />
              </Box>

              <Switch
                checked={account.is_active}
                onChange={() =>
                  handleToggle(account.id, bank.id, account.name, account.is_active)
                }
                color="primary"
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default BankAccountCard;