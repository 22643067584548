import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { colors } from '../../../themes/theme';

export const LinkButton = styled(Button)({
  backgroundColor: colors.primary,
  textTransform: 'none',
  height: '40px',
  fontSize: '0.85rem',
  whiteSpace: 'nowrap',
  borderRadius: '50px',
  color: colors.white,
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: colors.primaryHover,
  },
  '@media (max-width:600px)': {
    height: '35px',
    fontSize: '0.75rem',
    padding: '8px 15px',
  },
});

export const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    borderRadius: '25px',
    background: 'linear-gradient(145deg, #ffffff, #e3f2fd)',
    boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.2)',
    overflow: 'visible',
    border: `2px solid ${colors.lightGray2}`, 
    maxWidth: '600px',
    margin: '0 auto',
  },
});

export const StyledDialogContent = styled(DialogContent)({
  padding: '40px',
  textAlign: 'center',
  background: 'linear-gradient(to bottom, #e3f2fd, #ffffff)',
  borderRadius: '20px',
  boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: 'calc(100vh - 32px)', 
  overflow: 'hidden',
  '@media (max-width:600px)': {
    padding: '20px',
    maxHeight: 'calc(100vh - 20px)',
  },
});

export const StyledTitle = styled(Typography)({
  fontSize: '1.3rem',
  fontWeight: 'bold',
  color: colors.black,
  marginBottom: '15px',
  '@media (max-width:600px)': {
    fontSize: '1.5rem',
  },
});

export const StyledSubText = styled(Typography)({
  fontSize: '1rem',
  color: colors.mediumGray,
  marginBottom: '25px',
  '@media (max-width:600px)': {
    fontSize: '0.9rem',
    marginBottom: '20px',
  },
});

export const StyledProgressText = styled(Typography)({
  fontSize: '1rem',
  color: colors.black,
  marginBottom: '15px',
  '@media (max-width:600px)': {
    fontSize: '0.9rem',
  },
});

export const IconWrapper = styled(Box)({
  background: 'linear-gradient(to right, #42a5f5, #1e88e5)', 
  padding: '20px',
  borderRadius: '50%',
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
  marginBottom: '20px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (max-width:600px)': {
    padding: '15px',
    marginBottom: '15px',
  },
});
