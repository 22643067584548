import ReactGA from 'react-ga4';
import { CircularProgress, Box, useMediaQuery, useTheme } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import dayjs from 'dayjs';
import ReportFilter from './ReportFilter';
import {
  Container,
  ContainerBody,
  ContainerHeader,
  FlexItem,
  Title,
  Text,
  ExportButton,
} from './styled';
import FinancialStatement from './FinancialStatement';
import useDateRange from '../../common/hooks/useDateRange';
import useReportData from '../../common/hooks/useReportData';
import utc from 'dayjs/plugin/utc';
import { useDashboardContext } from '../../common/contexts/DashboardContext.js';
import { useEffect, useRef } from 'react';

dayjs.extend(utc);

const ReportsPage = () => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    minDateRangeLocal,
    maxDateRangeLocal,
  } = useDashboardContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const didInitialize = useRef(false);
  const { formatDate } = useDateRange(minDateRangeLocal, maxDateRangeLocal);

  useEffect(() => {
    ReactGA.event({
      category: 'Reports',
      action: 'Page View',
      label: isMobile ? 'Mobile' : 'Desktop',
    });
  }, [isMobile]);

  useEffect(() => {
    if (!didInitialize.current && startDate && endDate) {
      const initialStartDate = dayjs().startOf('year').toDate();
      const initialEndDate = dayjs().endOf('day').toDate();
      setStartDate(initialStartDate);
      setEndDate(initialEndDate);
      didInitialize.current = true;
      ReactGA.event({
        category: 'Reports',
        action: 'Initialize Date Range',
        label: `${initialStartDate} to ${initialEndDate}`,
      });
    }
  }, [startDate, endDate, setStartDate, setEndDate]);

  const { reportsData, handleExport, loading } = useReportData({
    startDate: dayjs.utc(startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
    endDate: dayjs.utc(endDate).format('YYYY-MM-DDTHH:mm:ssZ'),
  });

  const handleReset = () => {
    const resetStartDate = dayjs().startOf('year').toDate();
    const resetEndDate = dayjs().endOf('day').toDate();
    setStartDate(resetStartDate);
    setEndDate(resetEndDate);
    ReactGA.event({
      category: 'Reports',
      action: 'Reset Date Range',
    });
  };

  const trackExport = () => {
    ReactGA.event({
      category: 'Reports',
      action: 'Export Report',
      label: `${formatDate(startDate)} to ${formatDate(endDate)}`,
    });
    handleExport();
  };

  const isInitialDateRange = () => {
    if (!startDate || !endDate) return true;
    return (
      dayjs(startDate).isSame(dayjs().startOf('year'), 'day') &&
      dayjs(endDate).isSame(dayjs().endOf('day'), 'day')
    );
  };

  if (loading) return <Spinner />;

  const {
    income,
    costOfGoodsSold,
    operatingExpenses,
    netProfit,
    incomeData,
    costOfGoodsSoldData,
    operatingExpensesData,
    totalIncome,
    totalCostOfGoodsSold,
    totalOperatingExpenses,
    grossProfit,
  } = reportsData;

  const mobileHeader = (
    <ContainerHeader>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Title>Profit &amp; Loss</Title>
      </Box>
    </ContainerHeader>
  );

  const desktopHeader = (
    <ContainerHeader>
      <FlexItem>
        <Title>Profit &amp; Loss</Title>
      </FlexItem>
    </ContainerHeader>
  );

  const mobileAccountsHeader = (
    <ContainerHeader>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 1,
        }}
      >
        <Title>Accounts</Title>
        <Text>
          {formatDate(startDate)} to {formatDate(endDate)}
        </Text>
      </Box>
      <ExportButton
        variant="contained"
        color="primary"
        onClick={trackExport}
        startIcon={<ShareIcon />}
      >
        Export
      </ExportButton>
    </ContainerHeader>
  );

  const desktopAccountsHeader = (
    <ContainerHeader>
      <FlexItem>
        <Title>Accounts</Title>
      </FlexItem>
      <FlexItem dir="column">
        <Text>{formatDate(startDate)}</Text>
        <Text>to {formatDate(endDate)}</Text>
      </FlexItem>
    </ContainerHeader>
  );

  return (
    <>
      <Container>
        {isMobile ? mobileHeader : desktopHeader}
        <ContainerBody>
          <ReportFilter
            startDate={startDate}
            setStartDate={(date) => {
              setStartDate(date);
              ReactGA.event({
                category: 'Reports',
                action: 'Update Start Date',
                label: formatDate(date),
              });
            }}
            endDate={endDate}
            setEndDate={(date) => {
              setEndDate(date);
              ReactGA.event({
                category: 'Reports',
                action: 'Update End Date',
                label: formatDate(date),
              });
            }}
            income={income}
            costOfGoodsSold={costOfGoodsSold}
            operatingExpenses={operatingExpenses}
            netProfit={netProfit}
            minDateRangeLocal={minDateRangeLocal}
            maxDateRangeLocal={maxDateRangeLocal}
            handleReset={handleReset}
            isResetDisabled={isInitialDateRange()}
          />
        </ContainerBody>
        {!isMobile && (
          <ContainerHeader>
            <FlexItem justify="end">
              <ExportButton
                variant="contained"
                onClick={trackExport}
                startIcon={<ShareIcon />}
              >
                Export
              </ExportButton>
            </FlexItem>
          </ContainerHeader>
        )}
      </Container>
      <Container>
        {isMobile ? mobileAccountsHeader : desktopAccountsHeader}
        <ContainerBody padding="0px">
          <FinancialStatement
            incomeData={incomeData}
            costOfGoodsSoldData={costOfGoodsSoldData}
            operatingExpensesData={operatingExpensesData}
            totalIncome={'$' + totalIncome}
            totalCostOfGoodsSold={'$' + totalCostOfGoodsSold}
            grossProfit={'$' + grossProfit}
            grossProfitPercentage={
              parseInt(totalIncome) === 0
                ? 'N/A '
                : ((grossProfit / totalIncome) * 100).toFixed(2) + '%'
            }
            totalOperatingExpenses={'$' + totalOperatingExpenses}
            netProfit={'$' + netProfit.toFixed(2)}
            netProfitPercentage={
              parseInt(totalIncome) === 0
                ? 'N/A'
                : ((netProfit / totalIncome) * 100).toFixed(2) + '%'
            }
          />
        </ContainerBody>
      </Container>
    </>
  );
};

const Spinner = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: 'calc(100vh - 100px)',
    }}
  >
    <CircularProgress />
  </div>
);

export default ReportsPage;

