import ReactGA from 'react-ga4';
import React, { useEffect } from 'react';
import ChartHomePage from '../../../components/features/Data Visualizations';
import { useDashboardContext } from '../../../components/common/contexts/DashboardContext';

const ChartPage = () => {
  const {
    activeTimeTab,
    selectedWeeks,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    startDate,
    endDate,
  } = useDashboardContext();

  useEffect(() => {
    ReactGA.event({
      category: 'Charts',
      action: 'View',
      label: `TimeTab: ${activeTimeTab}`,
    });
  }, [activeTimeTab]);

  useEffect(() => {
    ReactGA.event({
      category: 'Charts',
      action: 'Date Range Change',
      label: `${startDate} to ${endDate}`,
    });
  }, [startDate, endDate]);

  return (
    <ChartHomePage
      activeTimeTab={activeTimeTab}
      selectedWeeks={selectedWeeks}
      selectedMonths={selectedMonths}
      selectedQuarters={selectedQuarters}
      selectedYears={selectedYears}
      startDate={startDate}
      endDate={endDate}
    />
  );
};

export default ChartPage;
