import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Typography, Snackbar, CircularProgress } from '@mui/material';
import { Alert } from '@mui/material';
import { AuthContext } from '../../../../../components/common/contexts/authContext';
import axiosInstance from '../../../../../api/axiosWrapper';
import Divider from '@mui/material/Divider';
import { Skeleton } from '@mui/material';
import NotificationSnackbar from './NotificationSnackbar';
import BankAccountCard from './BankAccountCard';
import LinkBankAccountModal from '../../../../../components/common/LinkBankAccountModal';
import ConfirmationDialog from './ConfirmationDialog';
import { colors } from '../../../../../themes/theme';
import { TRANSACTIONS_QUERY_KEY } from '../../../../../data/constants';
import { useQueryClient } from '@tanstack/react-query';

const BankAccountInfo = () => {
  const { currentUser, isLoggedIn } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const [bankAccounts, setBankAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [modalOpen, setModalOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [reauthItems, setReauthItems] = useState([]);
  const [currentLinkToken, setCurrentLinkToken] = useState(null);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [itemName, setItemName] = useState('');

  // dialog state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [verificationText, setVerificationText] = useState('');

  useEffect(() => {
    const fetchBankAccounts = async () => {
      if (currentUser && isLoggedIn) {
        setLoading(true);
        try {
          const response = await axiosInstance.get('/getPlaidItemsForUser');
          const { items, reauthItems } = response.data;
          setBankAccounts(items);
          setReauthItems(reauthItems);

          if (reauthItems && reauthItems.length > 0) {
            setCurrentLinkToken(reauthItems[0].linkToken);
            setOpenAlert(true);
            setModalOpen(true);
          }
        } catch (error) {
          console.error('Error fetching bank accounts:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchBankAccounts();
  }, [currentUser, isLoggedIn]);

  const handleModalClose = () => setModalOpen(false);

  const handleLinkNewBank = () => setModalOpen(true);

  const handleAlertClose = () => setOpenAlert(false);

  const handleSnackbarClose = () => setOpenSnackbar(false);

  // Open and close Confirmation Dialog
  const handleOpenDialog = (type, accountId = null, itemId, name) => {
    setDialogType(type);
    setSelectedAccountId(accountId);
    setSelectedItemId(itemId);
    setItemName(name); 
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogType('');
    setVerificationText('');
  };

  const handleToggleAccount = (accountId, itemId, accountName, activate) => {
    if (!activate) {
      setDialogType('account');
      setSelectedAccountId(accountId);
      setSelectedItemId(itemId);
      setItemName(accountName);
      setDialogOpen(true);
    } else {
      toggleAccount(accountId, itemId, accountName, activate);
    }
  };
  
  const toggleAccount = async (accountId, itemId, accountName, activate) => {
    try {
      setBankAccounts((prevAccounts) =>
        prevAccounts.map((bank) =>
          bank.id === itemId
            ? {
                ...bank,
                accounts: bank.accounts.map((account) =>
                  account.id === accountId
                    ? { ...account, is_active: activate }
                    : account
                ),
              }
            : bank
        )
      );
  
      await axiosInstance.post('/deactivateAccount', {
        accountId,
        itemId,
        activate,
      });
  
      setSnackbarMessage(
        activate
          ? `Account "${accountName}" reactivated successfully!`
          : `Account "${accountName}" deactivated successfully!`
      );
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
  
       // Invalidate and refetch transactions
    await queryClient.invalidateQueries({ queryKey: TRANSACTIONS_QUERY_KEY });
    await queryClient.refetchQueries({ queryKey: TRANSACTIONS_QUERY_KEY });
  } catch (error) {
      console.error('Failed to toggle account state:', error);
  
      // Revert state if API call fails
      setBankAccounts((prevAccounts) =>
        prevAccounts.map((bank) =>
          bank.id === itemId
            ? {
                ...bank,
                accounts: bank.accounts.map((account) =>
                  account.id === accountId
                    ? { ...account, is_active: !activate }
                    : account
                ),
              }
            : bank
        )
      );
  
      setSnackbarMessage('Failed to toggle account state. Please try again.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleUnlinkItem = async (itemId, itemName) => {
    try {
      await axiosInstance.post('/deactivatePlaidItem', { itemId });

      setBankAccounts((prevAccounts) =>
        prevAccounts.filter((bank) => bank.id !== itemId)
      );

      setSnackbarMessage(`Bank item "${itemName}" unlinked successfully!`);
      setSnackbarSeverity('success');
      setOpenSnackbar(true);

      // Clear cache
      // Invalidate and refetch transactions
    await queryClient.invalidateQueries({ queryKey: TRANSACTIONS_QUERY_KEY });
    await queryClient.refetchQueries({ queryKey: TRANSACTIONS_QUERY_KEY });
    } catch (error) {
      console.error('Error unlinking bank item:', error);
      setSnackbarMessage('Failed to unlink bank item. Please try again.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };
  
  const handleConfirmRemove = async () => {
    if (dialogType === 'account') {
      await toggleAccount(selectedAccountId, selectedItemId, itemName, false);
    } else if (dialogType === 'item') {
      await handleUnlinkItem(selectedItemId, itemName);
    }
    handleCloseDialog();
  };

  return (
    <Box sx={{ padding: '20px', backgroundColor: colors.lightGray, minHeight: '100vh' }}>
      <Typography variant="h5" sx={{ mb: 2, textAlign: 'left', fontWeight: 'bold' }}>
        Linked Accounts
      </Typography>

      {reauthItems.length > 0 && (
        <NotificationSnackbar
          open={openAlert}
          message="Some of your accounts require re-authentication."
          onClose={handleAlertClose}
          severity="warning"
        />
      )}

      {/* Connect Account Button */}
      <Button
        variant="contained"
        onClick={handleLinkNewBank}
        disabled={loading}
        sx={{
          display: 'block',
          borderRadius: '20px',
          ml: 'auto',
          mb: 2,
          backgroundColor: colors.primary,
          color: colors.white,
          textTransform: 'none',
          '&:hover': { backgroundColor: colors.primaryHover },
        }}
      >
        Add Account
      </Button>

      <Divider sx={{ mb: 2 }} />
      {loading ? (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <CircularProgress />
          <Box mt={2}>
            {[1, 2, 3].map((i) => (
              <Skeleton
                key={i}
                variant="rectangular"
                height={200}
                sx={{ mb: 2, borderRadius: '10px' }}
              />
            ))}
          </Box>
        </Box>
      ) : (
        bankAccounts.map((bank) => (
          <BankAccountCard
            key={bank.id}
            bank={bank}
            reauthItems={reauthItems}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
            setOpenSnackbar={setOpenSnackbar}
            handleOpenDialog={handleOpenDialog}  
            handleToggleAccount={handleToggleAccount}
          />
        ))
      )}

      {/* Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Modal  */}
      <LinkBankAccountModal
        open={modalOpen}
        handleClose={handleModalClose}
        linkToken={currentLinkToken}
      />

      {/* Confirmation Dialog */}
      <ConfirmationDialog
         open={dialogOpen}
         onClose={handleCloseDialog}
         onConfirm={handleConfirmRemove}
         dialogType={dialogType}
         itemName={itemName}  
         confirmationAction={dialogType === 'account' ? 'deactivate' : 'unlink'}
         verificationText={verificationText}
         setVerificationText={setVerificationText}
      />
    </Box>
  );
};

export default BankAccountInfo;
