import axiosInstance from '../../../api/axiosWrapper';

const fetchCategories = () => axiosInstance.get('/categories');

const getTransactions = async () => {
  const response = await axiosInstance.get('/transactions');
  return response;
};

const addTransaction = async (transaction) => {
  const response = await axiosInstance.post('/transactions', {
    date: transaction.date,
    description: transaction.description,
    category: [transaction.category],
    category_id: transaction.category_id,
    amount: transaction.amount,
  });

  return response;
};

const editTransaction = async (id, transaction) => {
  const response = await axiosInstance.put(`/transactions/${id}`, {
    authorized_date: transaction.date,
    name: transaction.description,
    category: [transaction.category],
    category_id: transaction.category_id,
    amount: transaction.amount,
  });
  return response;
};

/**
 * NOTE: this makes a PUT request rather than DELETE because we're updating
 * "is_removed" to "1" rather than actually removing the row from our database.
 *
 */
const markTransactionAsRemoved = async (id) => {
  const response = await axiosInstance.put(`/transactions/remove/${id}`);
  return response;
};

export {
  fetchCategories,
  getTransactions,
  addTransaction,
  editTransaction,
  markTransactionAsRemoved,
};
