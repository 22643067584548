import ReactGA from 'react-ga4';
import React, { useState, useEffect } from 'react';
import { DashboardProvider } from '../../../components/common/contexts/DashboardContext';
import MetricsDisplay from '../../../components/features/Metrics Display';
import useTransactions from '../../../components/common/hooks/useTransactions';
import AlertPopup from '../../../components/common/AlertPopup';
import { Box } from '@mui/material';
import { colors } from '../../../themes/theme';
import { Outlet } from 'react-router-dom';
import { NavigationTabs } from '../../../components/features/NavigationTabs';
import { useLocation } from 'react-router-dom';
import { store } from '../../../data/store';
import { useStore } from '@tanstack/react-store';

const Home = () => {
  const { fetchTransactions } = useTransactions();
  const [openAlert, setOpenAlert] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [timePeriod, setTimePeriod] = useState('all');
  const [filterState, setFilterState] = useState('All');

  const {
    transactions,
    accounts,
    isLoadingTransactions,
    minDateRange,
    maxDateRange,
    categories,
  } = useStore(store, (state) => ({
    transactions: state.transactions,
    accounts: state.accounts,
    isLoadingTransactions: state.isLoadingTransactions,
    minDateRange: state.minDateRange,
    maxDateRange: state.maxDateRange,
    categories: state.categories,
  }));

  const location = useLocation();

  useEffect(() => {
    ReactGA.event({
      category: 'Dashboard',
      action: 'Page View',
      label: location.pathname,
    });
  }, [location]);

  const handleTimePeriodChange = (newTimePeriod) => {
    ReactGA.event({
      category: 'Dashboard',
      action: 'Time Period Change',
      label: newTimePeriod,
    });
    setTimePeriod(newTimePeriod);
  };

  const handleFilterChange = (newFilterState) => {
    ReactGA.event({
      category: 'Dashboard',
      action: 'Filter Change',
      label: newFilterState,
    });
    setFilterState(newFilterState);
  };

  useEffect(() => {
    if (transactions.length > 0) {
      ReactGA.event({
        category: 'Dashboard',
        action: 'Transactions Loaded',
        value: transactions.length,
      });
    }
  }, [transactions]);

  if (isLoadingTransactions) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          fontFamily: 'Arial, sans-serif',
          color: colors.primary,
          fontSize: '1.5rem',
          letterSpacing: '0.1rem',
        }}
      >
        <span style={{ marginBottom: '10px', fontWeight: 'bold' }}>Loading your transactions</span>
        <div style={{ display: 'flex', gap: '4px' }}>
          <span className="loading-dot" style={{ animationDelay: '0s' }}>.</span>
          <span className="loading-dot" style={{ animationDelay: '0.2s' }}>.</span>
          <span className="loading-dot" style={{ animationDelay: '0.4s' }}>.</span>
        </div>
      </div>
    );
  }

  return (
    <DashboardProvider
      minDateRange={minDateRange}
      maxDateRange={maxDateRange}
      fetchTransactions={fetchTransactions}
    >
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.gray,
          height: 'fit-content',
          padding: '10px 0px',
          gap: '50px',
        }}
      >
        <NavigationTabs pathname={location.pathname} />
        {location.pathname !== '/dashboard/reports' && (
          <MetricsDisplay
            transactions={transactions}
            onTimePeriodChange={handleTimePeriodChange}
            filterState={filterState}
          />
        )}
        <Outlet
          context={{
            transactions,
            categories,
            timePeriod,
            accounts,
            loadingData: isLoadingTransactions,
            setOpenAlert,
            setAlertSeverity,
            setAlertMessage,
            fetchTransactions,
            handleFilterChange,
          }}
        />
        {openAlert && (
          <AlertPopup
            open={openAlert}
            setOpen={setOpenAlert}
            alertMessage={alertMessage}
            alertSeverity={alertSeverity}
          />
        )}
      </Box>
    </DashboardProvider>
  );
};

export default Home;
