import React from 'react';
import { Box, Typography } from '@mui/material';

const Legend = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    mt={2}
    sx={{
      gap: 3, 
      flexWrap: 'wrap', 
    }}
  >
    {/* Profit Legend */}
    <Box display="flex" alignItems="center" gap={1}>
      <Box
        sx={{
          width: 16,
          height: 16,
          backgroundColor: 'green',
          borderRadius: '4px', 
        }}
      ></Box>
      <Typography
        variant="body2"
        component="span"
        sx={{
          color: 'text.primary', 
          fontWeight: 500,
        }}
      >
        Profit
      </Typography>
    </Box>

    {/* Loss Legend */}
    <Box display="flex" alignItems="center" gap={1}>
      <Box
        sx={{
          width: 16,
          height: 16,
          backgroundColor: 'red',
          borderRadius: '4px',
        }}
      ></Box>
      <Typography
        variant="body2"
        component="span"
        sx={{
          color: 'text.primary',
          fontWeight: 500,
        }}
      >
        Loss
      </Typography>
    </Box>
  </Box>
);

export default Legend;
