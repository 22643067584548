import ReactGA from 'react-ga4';
import Signup from '../Register/Signup.js';
import Signin from '../Login/Signin';
import React, { useState, useContext } from 'react';
import { Box, Paper, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../common/contexts/authContext.js';

const LandingPage = () => {
  const [showSignIn, setShowSignIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { login, register, buttonLoading } = useContext(AuthContext); 
  const navigate = useNavigate();

  const [user, setUser] = useState({
    emailId: '',
    password: '',
  });

  const [registerUser, setRegisterUser] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
    password: '',
    confirmPassword: '',
  });

  const checkConfirmPasswordMatch = (registerUser) =>
    registerUser.password === registerUser.confirmPassword;

  // Form submission handler for both login and registration
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!showSignIn) {
      // Register flow
      try {
        if (!checkConfirmPasswordMatch(registerUser)) {
          ReactGA.event({
            category: 'Auth',
            action: 'Registration Error',
            label: 'Password Mismatch',
          });
          throw new Error('Passwords do not match. Please try again.');
        }

        await register(registerUser);
        ReactGA.event({
          category: 'Auth',
          action: 'Registration Success',
        });
      } catch (error) {
        console.error('Error during registration:', error);
        ReactGA.event({
          category: 'Auth',
          action: 'Registration Error',
          label: error.code || 'Unknown Error',
        });
        setErrorMessage(error.message); 
      }
    } else {
      // Login flow
      try {
        const loginReq = {
          emailId: registerUser?.emailId || user.emailId,
          password: registerUser?.password || user.password,
        };
        await login(loginReq);
        ReactGA.event({
          category: 'Auth',
          action: 'Login Success',
        });
      } catch (error) {
        ReactGA.event({
          category: 'Auth',
          action: 'Login Error',
          label: error.code || 'Unknown Error',
        });
        console.error('Error during login:', error);
        setErrorMessage(error.message); 
      }
    }
  };

  const handleSignInClick = () => {
    ReactGA.event({
      category: 'Auth',
      action: 'Switch to Sign In',
    });
    setShowSignIn(true);
    setErrorMessage('');
  };

  const handleSignUpClick = () => {
    ReactGA.event({
      category: 'Auth',
      action: 'Switch to Sign Up',
    });
    setShowSignIn(false);
    setErrorMessage('');
  };

  const handleResetPasswordClick = () => {
    ReactGA.event({
      category: 'Auth',
      action: 'Navigate to Reset Password',
    });
    navigate('/login/reset');
  };

  return (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', background: 'linear-gradient(145deg, #f3f4f6, #ffffff)'}}>
      <Paper elevation={6} sx={{ maxWidth: { xs: '100%', sm: '500px' }, width: '90%', borderRadius: '15px', }}>
        {showSignIn ? (
          <Signin
          handleSubmit={handleSubmit}
          user={user}
          setUser={setUser}
          handleSignUpClick={handleSignUpClick}
          handleResetPasswordClick={handleResetPasswordClick}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          buttonLoading={buttonLoading}
        />
      ) : (
        <Signup
          handleSubmit={handleSubmit}
          registerUser={registerUser}
          setRegisterUser={setRegisterUser}
          handleSignInClick={handleSignInClick}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          buttonLoading={buttonLoading}
        />
        )}
      </Paper>
    </Box>
  );
};

export default LandingPage;
