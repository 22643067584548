import React from 'react';
import { Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { colors } from '../../../../themes/theme';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const { income, expenses, netAmount } = payload[0].payload;

    const formattedLabel = dayjs(label, 'MMM').format('MMMM'); 

    return (
      <Paper
        elevation={3}
        style={{
          padding: '10px',
          borderRadius: '8px',
        }}
      >
        <Typography variant="subtitle2" component="p">
          {formattedLabel} 
        </Typography>
        <Typography
          variant="body2"
          component="p"
          style={{ color: colors.income, fontWeight: 'bold' }}
        >
          Income: ${income.toLocaleString()}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          style={{ color: colors.expenses, fontWeight: 'bold' }}
        >
          Expenses: -${expenses.toLocaleString()}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          style={{
            color: netAmount >= 0 ? colors.income : colors.expenses,
            fontWeight: 'bold',
          }}
        >
          {netAmount >= 0 ? 'Net Profit' : 'Net Loss'}: ${Math.abs(netAmount).toLocaleString()}
        </Typography>
      </Paper>
    );
  }

  return null;
};

export default CustomTooltip;
