import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { colors } from '../../../../themes/theme';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import BarChartBars from './BarChartBars';
import Legend from './Legend';

dayjs.extend(isBetween);

const ScrollContainer = styled(Box)({
  width: '100%',
  overflowX: 'auto',
  overflowY: 'hidden',
  '&::-webkit-scrollbar': {
    height: 12,
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: 6,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
});

const BarChartRecharts = ({
  transactions,
  timePeriod = 'year',
  selectedWeeks = [],
  selectedMonths = [],
  selectedQuarters = [],
  selectedYears = [],
  startDate,
  endDate,
}) => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (transactions.length > 0) {
      let filteredTransactions = [];

      // Define date key functions for grouping by month
      //const dateKeyFunc = (date) => date.format('MMM'); // Short month name (e.g., "Jan")

      // Filter transactions based on the selected time period
      switch (timePeriod) {
        case 'week':
          filteredTransactions = transactions.filter((transaction) => {
            const transactionDate = dayjs(transaction.authorized_date).local();
            return selectedWeeks.some((week) => {
              const [start, end] = week.split(' - ');
              const startDate = dayjs(`${start}, ${end.split(', ')[1]}`).startOf('day');
              const endDate = dayjs(end).endOf('day');
              return transactionDate.isBetween(startDate, endDate, null, '[]');
            });
          });
          break;

        case 'month':
          filteredTransactions = transactions.filter((transaction) => {
            const transactionDate = dayjs(transaction.authorized_date).local().format('MMMM YYYY');
            return selectedMonths.includes(transactionDate);
          });
          break;

        case 'quarter':
          filteredTransactions = transactions.filter((transaction) => {
            const transactionDate = dayjs(transaction.authorized_date).local();
            const quarter = `Q${transactionDate.quarter()} ${transactionDate.year()}`;
            return selectedQuarters.includes(quarter);
          });
          break;

        case 'year': // Default to Year-To-Date (YTD)
          const startOfYear = dayjs().startOf('year');
          const endOfYear = dayjs().endOf('year');
          filteredTransactions = transactions.filter((transaction) => {
            const transactionDate = dayjs(transaction.authorized_date).local();
            return transactionDate.isBetween(startOfYear, endOfYear, null, '[]');
          });
          break;

        case 'all':
          filteredTransactions = transactions.filter((transaction) => {
            const transactionDate = dayjs(transaction.authorized_date).local();
            const start = dayjs(startDate).startOf('day');
            const end = dayjs(endDate).endOf('day');
            return transactionDate.isBetween(start, end, null, '[]');
          });
          break;

        default:
          filteredTransactions = transactions;
          break;
      }

      // Group transactions by month and calculate net profit/loss
      const aggregatedData = filteredTransactions.reduce((acc, transaction) => {
        const month = dayjs(transaction.authorized_date).format('MMM'); // Group by month
        const amount = parseFloat(transaction.amount);
      
        if (!acc[month]) {
          acc[month] = { name: month, netAmount: 0, income: 0, expenses: 0 };
        }
      
        // Separate income and expenses
        if (amount >= 0) {
          acc[month].income += amount;
        } else {
          acc[month].expenses += Math.abs(amount); 
        }
      
        // Calculate netAmount as income - expenses
        acc[month].netAmount += amount;
      
        return acc;
      }, {});

      // Transform aggregated data into an array
      const transformedData = Object.keys(aggregatedData)
      .map((key) => ({
        name: key,
        netAmount: aggregatedData[key].netAmount,
        income: aggregatedData[key].income,
        expenses: aggregatedData[key].expenses,
      }))
      .sort((a, b) => dayjs(a.name, 'MMM').month() - dayjs(b.name, 'MMM').month());

      setData(transformedData);
    }
  }, [
    transactions,
    timePeriod,
    selectedWeeks,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    startDate,
    endDate,
  ]);

  // Calculate totals for Income and Expenses
  const totals = data.reduce(
    (totals, item) => {
      if (item.netAmount > 0) {
        totals.income += item.netAmount;
      } else {
        totals.expenses += Math.abs(item.netAmount);
      }
      return totals;
    },
    { income: 0, expenses: 0 }
  );

  if (!data || data.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px',
          color: colors.lightGray3,
          textAlign: 'center', 
        }}
      >
        No data available for this period
      </Box>
    );
  }

  const dateRange = data.length
  ? `${data[0].name} - ${data[data.length - 1].name}`
  : '';

  return (
    <Container
      sx={{
        padding: isSmallScreen ? 1 : 3, 
      }}
    >
      {/* net Profit/loss */}
      <Typography
        variant={isSmallScreen ? 'subtitle2' : 'subtitle1'} 
        align="left"
        sx={{
          color: colors.black,
          fontWeight: 'bold',
        }}
      >
        {totals.income - totals.expenses >= 0
          ? `Net Income ${dateRange}`
          : `Net Loss ${dateRange}`}
      </Typography>
  
      {/* Total */}
      <Typography
        variant={isSmallScreen ? 'h5' : 'h4'} 
        align="left"
        gutterBottom
        sx={{
          color:
            totals.income - totals.expenses >= 0
              ? colors.income
              : colors.expenses,
          fontWeight: 'bold',
        }}
      >
        ${Math.abs(totals.income - totals.expenses).toLocaleString()}
      </Typography>
  
      {/* Income and Expense Breakdown */}
      <Typography
        variant="body2"
        align="left"
        sx={{
          color: 'text.secondary',
          marginBottom: 1,
          fontSize: isSmallScreen ? '0.875rem' : '1rem', 
        }}
      >
        Income: ${totals.income.toLocaleString()} | Spent: ${totals.expenses.toLocaleString()}
      </Typography>

      {/* Bar Chart */}
      <Box
        sx={{
          mt: 2,
          marginLeft: isSmallScreen ? -2 : 0,
          maxWidth: '100%',
        }}
      >
        <ScrollContainer>
          <BarChartBars data={data} />
        </ScrollContainer>
      </Box>

      {/* Legend */}
      <Box
        sx={{
          display: 'flex',
          justifyContent:'center',
          flexWrap: 'wrap', 
        }}
      >
        <Legend />
      </Box>
    </Container>
  );
};

export default BarChartRecharts;
